import { CarModel } from "@/api/generated/BMWInterfaces"
import LinkWithParams from "@/lib/common/LinkWithParams"
import colors from "@/lib/theme/colors"
import { formatter, getCardTimeLeft, getStockText } from "@/lib/utils"
import styled from "@emotion/styled"
import { Button, Typography } from "@mui/material"
import Image from "next/image"
import { useEffect, useState } from "react"

const CarCard = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #d5d5d5;
  background: white;
  height: auto;
  min-height: 343px;
  padding: 0;
  position: relative;
  :hover {
    background-color: transparent;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    /* max-width: 250px; */
    height: 100%;
  }
`

const TimerContainer = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 0px 0px 0px 8px;
  background: rgba(35, 31, 32, 0.8);
  backdrop-filter: blur(2px);
  position: absolute;
  top: 0;
  right: 0;
`

const ImageContainer = styled.div`
  width: 100%;
  height: 146px;
  position: relative;
  flex-shrink: 0;
`

const ModelDetails = styled.div`
  display: flex;
  padding: 0px 8px 8px 8px;
  flex-direction: column;
  justify-content: center;
  gap: 11px;
  align-self: stretch;
  flex-grow: 1;
`

const ModelInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
  height: 100%;
`

const PriceActions = styled.div`
  display: flex;
  gap: 8px;
  align-self: stretch;
  flex-grow: 1;
  align-items: end;
`

const PricesBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
`

const TagsFlex = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  row-gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`

const LowerTags = styled.div`
  display: flex;
  /* flex-direction: column; */
  gap: 8px;
  flex-wrap: wrap;
`

const LowerTag = styled.div`
  display: flex;
  padding: 2px 8px;
  align-items: center;
  width: fit-content;
  gap: 4px;
  border-radius: 200px;
  background: ${colors.directVeryLightGray};
`

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledButton = styled(Button)`
  padding: 4px 8px 4px 2px;
  background-color: ${colors.directYellow};
`

export default function CarsCard({
  data,
  onClick,
  h2,
}: {
  data: CarModel
  onClick?: () => void
  h2?: boolean
}): React.ReactElement {
  const { stock, dueDate } = data
  const soldOut = stock === 0
  const stockText = getStockText(stock)
  const [timeLeft, setTimeLeft] = useState(getCardTimeLeft(dueDate))
  const [isMounted, setIsMounted] = useState(false)
  const tagsObject = data.tags as unknown as {
    year: string
    interior: string
    hand: string
    engineType: string
    color: string
  }
  useEffect(() => {
    if (soldOut) {
      return
    }
    const timer = setInterval(() => {
      setTimeLeft(getCardTimeLeft(dueDate))
    }, 1000)

    return () => clearInterval(timer)
  }, [dueDate, soldOut])

  useEffect(() => {
    setIsMounted(true)
  }, [])
  return (
    <CarCard disableRipple LinkComponent={LinkWithParams} href={`/cars/${data.id}`}>
      <ImageContainer>
        {data.mainImage?.imageUrl && (
          <Image
            src={data.mainImage.imageUrl}
            alt={data.mainImage.alt || data.name}
            fill
            style={{ objectFit: "contain" }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />
        )}
      </ImageContainer>
      <ModelDetails>
        <ModelInfo>
          <Typography variant="body1" fontSize={20} component={h2 ? "h2" : "p"}>
            {data.name}
          </Typography>
          <TagsFlex>
            {tagsObject.year && (
              <Tag>
                <Image alt="year" width={16} height={16} src="/assets/year.svg" />
                <Typography variant="body2">{tagsObject.year}</Typography>
              </Tag>
            )}
            {tagsObject.hand && (
              <Tag>
                <Image alt="year" width={16} height={16} src="/assets/car.svg" />
                <Typography variant="body2">{tagsObject.hand}</Typography>
              </Tag>
            )}
            {tagsObject.engineType && (
              <Tag>
                <Image alt="year" width={16} height={16} src="/assets/engine.svg" />
                <Typography variant="body2">{tagsObject.engineType}</Typography>
              </Tag>
            )}
            {tagsObject.color && (
              <Tag>
                <Image alt="year" width={16} height={16} src="/assets/color.svg" />
                <Typography variant="body2">{tagsObject.color}</Typography>
              </Tag>
            )}
            {tagsObject.interior && (
              <Tag>
                <Image alt="year" width={16} height={16} src="/assets/interior.svg" />
                <Typography variant="body2">{tagsObject.interior}</Typography>
              </Tag>
            )}
          </TagsFlex>
          <LowerTags>
            {data.specialOfferTag.specialTag
              .filter((el) => el.tag)
              .map((el, i) => (
                <LowerTag key={i}>
                  <Image alt="year" width={16} height={16} src="/assets/blackgift.svg" />
                  <Typography variant="body2">{el.tag}</Typography>
                </LowerTag>
              ))}
            {stockText && (
              <LowerTag>
                <Typography variant="body2">{stockText}</Typography>
              </LowerTag>
            )}
          </LowerTags>
        </ModelInfo>
        <BottomContent>
          {data.discountPrice > 0 ? (
            <Typography color={colors.directGray} variant="body3">
              מחיר מחירון (רכב חדש) {formatter.format(data.price)}
            </Typography>
          ) : null}
          <PriceActions>
            <PricesBox>
              {data.discountPrice > 0 ? (
                <Typography variant="body2">
                  מחיר
                  <br />
                  בהזדמנות
                  <br /> {formatter.format(data.discountPrice)}
                </Typography>
              ) : (
                <Typography variant="h3">{formatter.format(data.price)}</Typography>
              )}
            </PricesBox>
            <StyledButton
              size="small"
              variant="contained"
              color="secondary"
              role="presentation"
              tabIndex={-1}
              onClick={onClick}
              endIcon={<Image alt="" src={"/assets/chevronleft.svg"} width={16} height={16} />}
            >
              {soldOut ? "צור קשר" : "לפרטים ולהזמנה"}
            </StyledButton>
          </PriceActions>
        </BottomContent>
      </ModelDetails>
      <TimerContainer>
        <Typography color={"white"} variant="body2" fontWeight={500}>
          {soldOut ? "Sold Out" : <>{isMounted ? timeLeft : ""}</>}
        </Typography>
      </TimerContainer>
    </CarCard>
  )
}
